.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.swiper-slide div {
    width: 100%;
}

.c-carousel-pagination{
    display: flex;
    margin-top: 20px;
    width: 100%; 
    gap: 10px;
}

.c-carousel-pagination__item {
    flex: 1; 
    height: 5px; 
    background-color: #ccc; 
    position: relative;
    //margin: 0 5px; 
}

.c-carousel-pagination__bullet {
    width: 0%;
    left: 0;
    transition: width 3s ease-in-out;

    &.active {
        width: 100%;
        left: auto;
    }
}

.c-carousel-pagination__item div {
    position: absolute;
    height: 100%;
    top: 0;
    background-color: black; 
    transition: width 3s ease-in-out;
}

